import Brick from "../../../assets/images/brick.inline.svg";
import Sheet from "../../../assets/images/sheet.inline.svg";
import Border from "../../../assets/images/border.inline.svg";
import { spacing16, spacing20, spacing32, spacing4, spacing6, spacing8 } from "@litbase/alexandria";
import styled from "styled-components";
import { down } from "styled-breakpoints";
import { T } from "../../translate-component";
import { StaticImage } from "gatsby-plugin-image";
import { RelativeContainer } from "./common-styles";

export function TopSection() {
  return (
    <>
      <Row id="our-company">
        <Col>
          <h1>
            <T>ourCompany</T>
          </h1>
          <p>
            <T>ourCompanyP1</T>
          </p>
          <p>
            <T>ourCompanyP2</T>
          </p>
          <p>
            <T>ourCompanyP3</T>
          </p>
          <h2>
            <T>whatWeSell</T>
          </h2>
          <ListItem>
            <ListPoint>
              <Brick />
            </ListPoint>
            <T>rubberBricks</T>
          </ListItem>
          <ListItem>
            <ListPoint>
              <Sheet />
            </ListPoint>
            <T>rubberSheets</T>
          </ListItem>
          <ListItem>
            <ListPoint>
              <Border />
            </ListPoint>
            <T>rubberEdgings</T>
          </ListItem>
        </Col>
        <StyledImageContainer>
          <RelativeContainer>
            <StaticImage src="../../../assets/images/shelves.png" alt="Polcok" width={500} height={500} />
            <Pattern />
          </RelativeContainer>
        </StyledImageContainer>
      </Row>
    </>
  );
}

export const Pattern = styled.div`
  position: absolute;
  top: -${spacing16};
  left: -${spacing20};
  width: 80%;
  height: 70%;
  z-index: -1;
  background: radial-gradient(${({ theme }) => theme.gray} 20%, transparent 20%),
    radial-gradient(${({ theme }) => theme.gray} 20%, transparent 20%);
  background-size: 45px 45px;
  ${down("md")} {
    display: none;
  }
`;

export const Col = styled.div`
  flex: 1;
  z-index: 2;
`;

export const Row = styled.div`
  display: flex;
  padding-bottom: ${spacing32};
  width: 100%;
  &:first-child {
    padding-top: ${spacing32};
    ${down("md")} {
      padding-top: ${spacing8};
    }
  }
  ${down("xl")} {
    padding: ${spacing6};
  }
  ${down("md")} {
    flex-direction: column;
    &:nth-child(2) {
      flex-direction: column-reverse;
    }
  }
`;

export const ImageContainer = styled.div`
  position: relative;
  aspect-ratio: 1;
  flex: 1;
  min-width: 0;
  &:first-child {
    margin-right: ${spacing32};
  }
  &:last-child {
    margin-left: ${spacing16};
  }
  z-index: 1;
  ${down("md")} {
    margin-top: ${spacing8};
    margin-left: 0;
    &:first-child {
      margin-right: 0;
    }
    &:last-child {
      margin-left: 0;
    }
  }
`;

const StyledImageContainer = styled(ImageContainer)`
  aspect-ratio: 1;
  margin-left: ${spacing16};
`;

const ListPoint = styled.div`
  height: 50px;
  width: 50px;
  background: ${({ theme }) => theme.medium};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${spacing4};
  border-radius: 8px;
`;

const ListItem = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;
  margin-top: 28px;
  font-weight: 600;
  & + & {
    margin-top: 24px;
  }
`;

import { Col, ImageContainer, Pattern, Row } from "./top-section";
import styled from "styled-components";
import { spacing12, spacing16, spacing2, spacing24, spacing4, spacing8 } from "@litbase/alexandria";
import { Check } from "@styled-icons/boxicons-regular";
import { ReactNode } from "react";
import { down } from "styled-breakpoints";
import { T } from "../../translate-component";
import { StaticImage } from "gatsby-plugin-image";
import { RelativeContainer } from "./common-styles";

export function Benefits() {
  return (
    <Row>
      <ImageContainer>
        <RelativeContainer>
          <BotLeftPattern />
          <StaticImage src="../../../assets/images/running.png" alt="" width={500} height={500} />
        </RelativeContainer>
      </ImageContainer>
      <Col>
        <h2>
          <T>benefits</T>
        </h2>
        <FeatureGrid>
          <Feature>
            <FeatureTitle>
              <T>soft</T>
            </FeatureTitle>
            <FeatureDescription>
              <T>reducesStress</T>
            </FeatureDescription>
          </Feature>
          <Feature>
            <FeatureTitle>
              <T>safe</T>
            </FeatureTitle>
            <FeatureDescription>
              <T>safeSub</T>
            </FeatureDescription>
          </Feature>
          <Feature>
            <FeatureTitle>
              <T>simple</T>
            </FeatureTitle>
            <FeatureDescription>
              <T>simpleSub</T>
            </FeatureDescription>
          </Feature>
          <Feature>
            <FeatureTitle>
              <T>practical</T>
            </FeatureTitle>
            <FeatureDescription>
              <T>practicalSub</T>
            </FeatureDescription>
          </Feature>
          <Feature>
            <FeatureTitle>
              <T>sturdy</T>
            </FeatureTitle>
            <FeatureDescription>
              <T>sturdySub</T>
            </FeatureDescription>
          </Feature>
        </FeatureGrid>
      </Col>
    </Row>
  );
}

const FeatureGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  grid-row-gap: ${spacing12};
  grid-column-gap: ${spacing4};
  margin-top: ${spacing16};
  ${down("md")} {
    margin-top: ${spacing8};
    grid-row-gap: ${spacing8};
  }
`;

const FeatureTitle = styled.span`
  font-size: 18px;
  color: ${({ theme }) => theme.black};
  font-weight: 600;
  width: fit-content;
`;

const FeatureDescription = styled.span`
  color: ${({ theme }) => theme.secondaryText};
  font-size: 14px;
  max-width: 250px;
`;

function Feature({ children }: { children: ReactNode }) {
  return (
    <FeatureBody>
      <FeatureCol>
        <StyledCheck />
      </FeatureCol>
      <FeatureCol>{children}</FeatureCol>
    </FeatureBody>
  );
}

const StyledCheck = styled(Check)`
  font-size: ${({ theme }) => theme.text3xl};
  color: ${({ theme }) => theme.medium};
`;

const FeatureCol = styled.div`
  display: flex;
  flex-direction: column;
  & + & {
    margin-left: ${spacing2};
  }
`;

const FeatureBody = styled.div`
  display: flex;
  width: fit-content;
`;

const BotLeftPattern = styled(Pattern)`
  top: auto;
  left: auto;
  right: -${spacing16};
  bottom: -${spacing24};
  width: 440px;
  height: 375px;
  ${down("lg")} {
    width: 280px;
    height: 280px;
  }
`;

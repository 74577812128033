import styled from "styled-components";
import {
  FontWeight,
  HookForm,
  HookInputField,
  HookSelectField,
  HookSwitchField,
  HookTextareaField,
  spacing12,
  spacing16,
  spacing2,
  spacing24,
  spacing3,
  spacing4,
  spacing6,
  spacing8,
} from "@litbase/alexandria";
import { Envelope, Map, Phone, User } from "@styled-icons/boxicons-regular";
import { Button } from "../basic/button";
import { Pattern } from "./our-company/top-section";
import { breakPointsPixels } from "../../styles/theme-types";
import { down, up } from "styled-breakpoints";
import { Quotation, sendQuotationFunction } from "@pot4/common/controller/pot4-controller-interface";
import { client } from "@litbase/client";
import { useRef, useState } from "react";
import { boolean, object, string } from "yup";
import { T } from "../translate-component";
import Reaptcha from "reaptcha";

export function ContactBlock() {
  const [isSend, setSend] = useState(false);
  const recaptchaRef = useRef<Reaptcha>(null);

  return (
    <StyledSection id="contact">
      <BlueCol>
        <ColPattern />
        <BlueColTitle>
          <T>contact</T>
        </BlueColTitle>
        <BlueColSubtitle>Polimeri T4 Services Kft.</BlueColSubtitle>
        <List>
          <ListElem>
            <StyledMap />
            <span>
              <T>address</T>
            </span>
          </ListElem>
          <ListElem>
            <User />
            <span>Takács Mihály</span>
          </ListElem>
          <ListElem>
            <Phone />
            <span>+36 20 581 8004</span>
          </ListElem>
          <ListElem>
            <Envelope />
            <span>info@pot4.hu</span>
          </ListElem>
        </List>
      </BlueCol>
      {!isSend ? (
        <FormCol id="quote">
          <StyledPattern />
          <h1>
            <T>RequestQuote</T>
          </h1>
          <HookForm
            onSubmit={async (values: Quotation & { accepted: boolean }) => {
              try {
                if (!values.accepted) {
                  window.alert(
                    "A feltételek elfogadása kötelező / The conditions must be accepted before continuing continue"
                  );
                  return;
                }

                await recaptchaRef.current?.execute();
                const captcha = await recaptchaRef.current?.getResponse();

                await client.callHttp(sendQuotationFunction, {
                  email: values.email,
                  userName: values.userName,
                  phoneNumber: values.phoneNumber,
                  brickType: values.brickType,
                  quantity: values.quantity,
                  message: values.message,
                  token: captcha,
                });
                setSend(true);
              } catch (e) {
                console.error(e, "Email küldés hiba");
              }
            }}
            yupSchema={confirmSchema}
          >
            {/* @ts-ignore */}
            <StyledInput name="userName" label={<T>name</T>} />
            {/* @ts-ignore */}
            <StyledInput name="email" label={<T>email</T>} />
            {/* @ts-ignore */}
            <StyledInput name="phoneNumber" label={<T>phone</T>} />
            <Row>
              <HookSelectField
                styles={{
                  control: (provided) => ({
                    ...provided,
                    border: "1px solid #C6C5DD",
                    background: "transparent",
                    borderRadius: "8px",
                  }),
                }}
                name="brickType"
                label={<T>product</T>}
              />
              <QuantityWrapper>
                {/* @ts-ignore */}
                <QuantityInput name="quantity" label={<T>quantity</T>} />
                <Unit>
                  m<sup>2</sup>
                </Unit>
              </QuantityWrapper>
            </Row>
            <StyledTextArea name="message" label={<T>message</T>} />
            <SwitchRow>
              <StyledSwitch name="accepted" />
              <span>
                <T>iAccept</T>{" "}
                <A>
                  <T>privacyPolicy</T>
                </A>
              </span>
            </SwitchRow>
            {/* @ts-ignore */}
            <Reaptcha ref={recaptchaRef} sitekey={process.env.RECAPTCHA_SITE as string} size="invisible" />
            <SendButton>
              <T>getQuote</T>
            </SendButton>
          </HookForm>
        </FormCol>
      ) : (
        <>
          <FormCol>
            <StyledPattern />
            <h1>
              <T>thanks</T>
              <br /> <T>soon</T>
            </h1>
          </FormCol>
        </>
      )}
    </StyledSection>
  );
}

const confirmSchema = object().shape({
  userName: string().required("A felhasználó név megadása kötelező"),
  email: string().email("Az email cím formátuma nem megfelelelő").required("Az email cím megadása kötelező"),
  phoneNumber: string().required("A telefonszám megadása kötelező"),
  accepted: boolean().oneOf([true], "Kötelező elfogadni"),
});

const StyledMap = styled(Map)`
  ${down("sm")} {
    height: 46px !important;
    width: 46px !important;
  }
`;
const StyledPattern = styled(Pattern)`
  top: auto;
  left: auto;
  height: 50%;
  width: 280px;
  right: 0;
  bottom: ${spacing16};
  ${down("md")} {
    display: none;
  }
`;

const SendButton = styled(Button)`
  font-size: 14px;
  width: 100%;
  padding: ${spacing3} 0;
  font-weight: ${FontWeight.Medium};
  box-shadow: none;
`;

const A = styled.a`
  color: ${({ theme }) => theme.black};
  text-decoration: underline !important;
  cursor: pointer;
`;

const StyledSwitch = styled(HookSwitchField)`
  margin-right: ${spacing4};
  > div {
    box-shadow: none;
  }
`;

const StyledTextArea = styled(HookTextareaField)`
  border: 1px solid ${({ theme }) => theme.lightGray};
  border-radius: 8px;
  background: ${({ theme }) => theme.white};
`;

const Row = styled.div`
  display: flex;
  padding-top: ${spacing6};
  padding-bottom: ${spacing6};
  > * {
    margin-top: 0 !important;
    flex: 1;
  }
  > * + * {
    margin-left: ${spacing6};
  }
`;

const SwitchRow = styled(Row)`
  > div {
    flex: 0;
  }
  > * + * {
    margin-left: ${spacing2};
  }
  align-items: center;
`;

const StyledInput = styled(HookInputField)`
  width: 100%;
  background: ${({ theme }) => theme.white};
  border-color: ${({ theme }) => theme.lightGray};
  border-radius: 8px;
`;

const QuantityInput = styled(HookInputField)`
  width: 100%;
  display: block;
  background: ${({ theme }) => theme.white};
  border-color: ${({ theme }) => theme.lightGray};
  border-radius: 8px;
`;

const QuantityWrapper = styled.div`
  position: relative;
`;
const Unit = styled.span`
  position: absolute;
  display: block;
  right: 15px;
  top: 30px;
  z-index: 9;
`;

const FormCol = styled.div`
  display: flex;
  width: 100%;
  ${up("lg")} {
    height: 100%;
    padding-top: ${spacing24};
  }
  min-height: fit-content;
  flex-direction: column;
  position: relative;
  z-index: 1;
  padding: ${spacing16};
  > form {
    z-index: 2;
    max-width: 450px;
  }
  label {
    color: ${({ theme }) => theme.secondaryText};
    font-weight: 600;
    font-size: 14px;
  }
  flex: 1;
  ${down("lg")} {
    padding: ${spacing8};
  }
`;

const ListElem = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;
  > svg {
    height: 32px;
    width: 32px;
    margin-right: ${spacing3};
  }
  & + & {
    margin-top: ${spacing8};
  }
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
`;

const BlueColSubtitle = styled.span`
  font-size: 24px;
  margin-top: ${spacing8};
  margin-bottom: ${spacing12};
  white-space: nowrap;
`;

const BlueColTitle = styled.span`
  font-size: 36px;
  white-space: nowrap;
`;

const ColPattern = styled.div`
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.17) 0%, rgba(255, 255, 255, 0.21) 100%);
  position: absolute;
  bottom: 0;
  right: 0;
  height: 50%;
  width: 40%;
  clip-path: polygon(0 50%, 100% 0, 100% 100%, 0 100%);
  ${down("md")} {
    width: 100%;
    height: 100%;
    clip-path: polygon(0 80%, 100% 20%, 100% 100%, 0 100%);
  }
`;

const BlueCol = styled.div`
  background: linear-gradient(180deg, ${({ theme }) => theme.medium} 0%, ${({ theme }) => theme.light} 100%);
  display: flex;
  flex-direction: column;
  padding: ${spacing8};
  padding-top: ${spacing16};
  color: white;
  padding-left: calc((100vw - ${breakPointsPixels.xl}px) / 2);
  padding-right: ${spacing16};
  position: relative;

  ${down("xl")} {
    padding: ${spacing16};
  }

  ${up("lg")} {
    padding-top: ${spacing24};
  }

  ${down("lg")} {
    width: 100%;
    padding: ${spacing16};
  }
`;

const StyledSection = styled.section`
  padding-left: 0;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  padding-top: 0;
  padding-right: 0;
  ${up("lg")} {
    min-height: 100vh;
  }
  ${down("lg")} {
    flex-direction: column;
  }
`;

import { CenteredTitle } from "../../styles/theme-types";
import styled from "styled-components";
import {
  FontWeight,
  spacing1,
  spacing16,
  spacing2,
  spacing4,
  spacing56,
  spacing6,
  spacing8,
} from "@litbase/alexandria";
import { down } from "styled-breakpoints";
import "react-aspect-ratio/aspect-ratio.css";
import { AspectRatio } from "react-aspect-ratio";
import { T } from "../translate-component";
import { ReactNode } from "react";
import { StaticImage } from "gatsby-plugin-image";

export function ReferencesBlock() {
  return (
    <StyledSection id="gallery">
      <StyledTitle>
        <T>gallery</T>
      </StyledTitle>

      <Grid>
        <Reference>
          <StaticImage src="../../assets/images/gallery1.png" alt="" width={870} height={630} />
        </Reference>
        <Reference>
          <StaticImage src="../../assets/images/gallery5.png" alt="" width={870} height={630} />
        </Reference>
        <Reference>
          <StaticImage src="../../assets/images/gallery4.png" alt="" width={870} height={630} />
        </Reference>
        <Reference>
          <StaticImage src="../../assets/images/gallery2.png" alt="" width={870} height={630} />
        </Reference>
        <Reference>
          <StaticImage src="../../assets/images/gallery3.png" alt="" width={870} height={630} />
        </Reference>
        <Reference>
          <StaticImage src="../../assets/images/gallery6.png" alt="" width={870} height={630} />
        </Reference>
      </Grid>
    </StyledSection>
  );
}

interface ReferenceProps {
  title?: string;
  subtitle?: string;
  children?: ReactNode;
}

function Reference({ title, subtitle, children }: ReferenceProps) {
  return (
    <ReferenceBody>
      <ReferenceImageWrapper ratio={1.4}>{children}</ReferenceImageWrapper>
      <ReferenceTitle>{title}</ReferenceTitle>
      <ReferenceSubtitle>{subtitle}</ReferenceSubtitle>
    </ReferenceBody>
  );
}

const StyledSection = styled.section`
  padding-bottom: ${spacing56};
`;

const ReferenceBody = styled.div`
  display: flex;
  flex-direction: column;
`;

const ReferenceSubtitle = styled.span`
  font-size: 14px;
  color: ${({ theme }) => theme.secondaryText};
`;

const ReferenceTitle = styled.span`
  font-weight: ${FontWeight.Medium};
  font-size: 18px;
  margin-top: ${spacing2};
  margin-bottom: ${spacing1};
`;

const ReferenceImageWrapper = styled(AspectRatio)`
  width: 100%;
  border-radius: 8px;
  box-shadow: ${({ theme }) => theme.shadow};
  overflow: hidden;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(520px, 1fr));
  grid-row-gap: ${spacing16};
  grid-column-gap: ${spacing8};
  margin-top: ${spacing16};
  width: 100%;

  ${down("xl")} {
    padding: 0 ${spacing6};
    grid-template-columns: repeat(auto-fill, minmax(480px, 1fr));
  }
  ${down("md")} {
    grid-template-columns: repeat(auto-fill, minmax(420px, 1fr));
  }
  ${down("sm")} {
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  }
`;

const StyledTitle = styled(CenteredTitle)`
  margin-bottom: ${spacing4};
`;

import { HeroBlock } from "../components/hero/hero-block";
import { OurCompanyBlock } from "../components/hero/our-company/our-company-block";
import { ReferencesBlock } from "../components/hero/references-block";
import { ContactBlock } from "../components/hero/contact-block";
import { Layout } from "../components/layout";

export default function LandingPage(props) {
  return (
    <Layout {...props}>
      <HeroBlock />
      <OurCompanyBlock />
      <ReferencesBlock />
      <ContactBlock />
    </Layout>
  );
}

import { TopSection } from "./top-section";
import { Benefits } from "./benefits";
import { spacing16 } from "@litbase/alexandria";
import styled from "styled-components";
import { LocationRecommendations } from "./location-recommendations";
import { down } from "styled-breakpoints";

export function OurCompanyBlock() {
  return (
    <Body>
      <Container>
        <TopSection />
        <Benefits />
        <LocationRecommendations />
      </Container>
    </Body>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Body = styled.section`
  padding-top: ${spacing16};
  ${down("md")} {
    padding-top: 0;
  }
`;

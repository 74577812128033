import { FontWeight, spacing4, spacing8 } from "@litbase/alexandria";
import styled, { css } from "styled-components";

export const Button = styled.button<{ $kind?: string }>`
  padding: ${spacing4} ${spacing8};
  font-size: 18px;
  background: ${({ theme }) => theme.medium};
  font-weight: ${FontWeight.Medium};
  color: white;
  border: none;
  border-radius: 12px;
  box-shadow: ${({ theme }) => theme.shadow};
  ${({ $kind }) => {
    switch ($kind) {
      case "secondary":
        return secondaryStyles;
      default:
        return "";
    }
  }};
`;

const secondaryStyles = css`
  background: white;
  color: ${({ theme }) => theme.medium};
`;

import styled, { css } from "styled-components";
import { gatsbyImage } from "../../../styles/gatsby-stuff";

export const sideImageStyles = css`
  border-radius: 8px;
  height: auto;
  width: 100%;
  aspect-ratio: 1;
  z-index: 1;
  box-shadow: ${({ theme }) => theme.shadow};
`;

export const RelativeContainer = styled.div`
  position: relative;

  > ${gatsbyImage} {
    ${sideImageStyles};
  }
`;

import styled from "styled-components";
import { FontWeight, spacing32, spacing48, spacing6, spacing8 } from "@litbase/alexandria";
import { Button } from "../basic/button";
import { down, up } from "styled-breakpoints";
import { Link } from "gatsby";
import { T } from "../translate-component";
import { StaticImage } from "gatsby-plugin-image";
import { gatsbyImage } from "../../styles/gatsby-stuff";
import { getLinkPath, jumpToElement } from "../../links";

export function HeroBlock() {
  return (
    <Body id="hero">
      <Part>
        <BackgroundImageContainer>
          <StaticImage src="../../assets/images/playground.png" alt="" />
          <BackgroundWrapper>
            <TextDiv>
              <HeroSecondary>Polimeri T4 Services Kft.</HeroSecondary>
              <HeroPrimary>
                „<T>heroPrimary</T>”
              </HeroPrimary>
              <HeroThird>PoT4</HeroThird>
              <Row>
                <QuoteButton
                  onClick={(e) => {
                    e.preventDefault();
                    jumpToElement("#contact");
                  }}
                >
                  <T>getQuote</T>
                </QuoteButton>
                <Link to={getLinkPath("/termekek")}>
                  <ProductsButton $kind="secondary">
                    <T>products</T>
                  </ProductsButton>
                </Link>
              </Row>
            </TextDiv>
          </BackgroundWrapper>
        </BackgroundImageContainer>
      </Part>
    </Body>
  );
}

const TextDiv = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: ${spacing48};
  margin: auto;
  max-width: 800px;
  text-align: center;
  align-items: center;
  justify-content: center;

  ${down("md")} {
    // display: none;
  }
`;

const QuoteButton = styled(Button)`
  ${up("md")} {
    width: 240px;
  }
`;

const ProductsButton = styled(Button)`
  ${up("md")} {
    width: 240px;
  }
`;

const BackgroundWrapper = styled.div`
  background: rgba(67, 56, 202, 0.3);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  ${down("md")} {
    // display: none;
  }
`;

const HeroThird = styled.div`
  color: ${({ theme }) => theme.white};
  font-style: italic;
  font-size: 22px;
  ${down("md")} {
    text-align: center;
    font-size: 18px;
  }
`;
const Row = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  margin-top: ${spacing6};
  > * + * {
    margin-left: ${spacing6};
  }
`;

const HeroPrimary = styled.span`
  color: ${({ theme }) => theme.white};
  font-weight: ${FontWeight.Bold};
  font-size: 64px;
  ${down("md")} {
    text-align: center;
    font-size: 36px;
  }
`;

const HeroSecondary = styled.span`
  color: ${({ theme }) => theme.white};
  font-weight: ${FontWeight.Medium};
  font-size: 34px;
  opacity: 0.7;
  ${down("md")} {
    opacity: 1;
    text-align: center;
    font-size: 28px;
  }
`;

const Part = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: #f9fafc;
  ${down("md")} {
    width: 100%;
  }
`;

const BackgroundImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  > ${gatsbyImage} {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
`;

const Body = styled.div`
  display: flex;
  height: 100vh;
  min-height: 100vh;
  padding-right: 0;
  padding-left: 0;
  padding-top: 0;
  ${down("md")} {
    flex-direction: column;
  }
`;

import { Col, Pattern, Row } from "./top-section";
import styled from "styled-components";
import { ReactNode } from "react";
import { ChevronRight } from "@styled-icons/boxicons-regular";
import {
  FontWeight,
  spacing12,
  spacing16,
  spacing24,
  spacing32,
  spacing4,
  spacing6,
  spacing8,
} from "@litbase/alexandria";
import { down } from "styled-breakpoints";
import { AspectRatio } from "react-aspect-ratio";
import { T } from "../../translate-component";
import { StaticImage } from "gatsby-plugin-image";

export function LocationRecommendations() {
  return (
    <>
      <CenteredTitle>
        <T>where</T>
      </CenteredTitle>
      <StyledRow>
        <StyledCol>
          <ImageWrapper ratio={1.4}>
            <StaticImage src="../../../assets/images/gym.png" alt="" width={400} height={300} />
          </ImageWrapper>
          <UnorderedList>
            <ListElem>
              <T>whereL1</T>
            </ListElem>
            <ListElem>
              <T>whereL2</T>
            </ListElem>
            <ListElem>
              <T>whereL3</T>
            </ListElem>
          </UnorderedList>
        </StyledCol>
        <StyledCol>
          <ImageContainer>
            <StyledPattern />
            <ImageWrapper ratio={1.4}>
              <StaticImage src="../../../assets/images/garden.png" alt="" width={400} height={300} />
            </ImageWrapper>
          </ImageContainer>
          <UnorderedList>
            <ListElem>
              <T>whereL4</T>
            </ListElem>
            <ListElem>
              <T>whereL5</T>
            </ListElem>
            <ListElem>
              <T>whereL6</T>
            </ListElem>
          </UnorderedList>
        </StyledCol>
      </StyledRow>
    </>
  );
}

function UnorderedList({ children }: { children: ReactNode }) {
  return <ListBody>{children}</ListBody>;
}

function ListElem({ children }: { children: ReactNode }) {
  return (
    <ListElemBody>
      <ListDecorator />
      {children}
    </ListElemBody>
  );
}

const StyledPattern = styled(Pattern)`
  left: -${spacing16};
  width: 60%;
  ${down("xl")} {
    display: none;
  }
`;

const ImageContainer = styled.div`
  position: relative;
  z-index: 1;
`;

const StyledCol = styled(Col)`
  z-index: 3;
  & + & {
    margin-left: ${spacing32};
    ${down("xl")} {
      margin-left: ${spacing16};
    }
    ${down("md")} {
      margin-left: 0;
      margin-top: ${spacing8};
    }
  }
`;

const StyledRow = styled(Row)`
  justify-content: center;
  ${down("md")} {
    flex-direction: column;
  }
`;

const ImageWrapper = styled(AspectRatio)`
  border-radius: 8px;
  box-shadow: ${({ theme }) => theme.shadow};
  width: 100%;
  overflow: hidden;
`;

const ListDecorator = styled(ChevronRight)`
  min-width: ${({ theme }) => theme.text2xl};
  min-height: ${({ theme }) => theme.text2xl};
  margin-right: ${spacing4};
  color: ${({ theme }) => theme.medium};
`;

const ListElemBody = styled.div`
  display: flex;
  max-width: 500px;
  color: ${({ theme }) => theme.secondaryText};
  font-weight: ${FontWeight.Medium};
  & + & {
    margin-top: ${spacing4};
  }
`;

const ListBody = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${spacing8};
  z-index: 3;
`;

const CenteredTitle = styled.h2`
  display: flex;
  justify-content: center;
  margin-bottom: ${spacing24};
  ${down("xl")} {
    margin-bottom: ${spacing12};
    margin-top: ${spacing32} !important;
  }
  ${down("md")} {
    margin: 0;
    margin-left: ${spacing6};
    justify-content: flex-start;
    margin-top: ${spacing6};
  }
`;

import { RpcDefinition } from "@litbase/core";

export interface Quotation {
  email: string;
  userName: string;
  phoneNumber: string;
  brickType: string;
  quantity: string;
  message: string;
  token: string;
}

export const sendQuotationFunction = new RpcDefinition<[Quotation], void>("sendQuotation");
